import React from 'react'
import Layout from '../components/layout'
import { MediumAtricles } from '../components/medium'
import { ProjectHeaderComponent, ProjectLinksComponent, TwitterTimeline } from '../components/project'
import SEO from '../components/seo'
import { NewsList } from '../components/news'

const ProjectTemplate = ({ data }) => {

  const twitterProfileName = data.strapiProject.twitter_link?.match(/^https?:\/\/(www\.)?twitter\.com\/(#!\/)?([^\/]+)(\/\w+)*$/)[3];

  const projectId = data.strapiProject.articles[0]?.project;

  const news = data.allStrapiArticle.edges
    .filter(x => x.node.project?.id == projectId)
    .map(x => {
      return {
        id: x.node.id,
        content: x.node.content,
        author: x.node.author,
        title: x.node.title,
        creationTime: x.node.creationTime,
        image: x.node.image,
      }
    })

  return (
    <Layout>
      <SEO title="Project" />
      <div className="mgt-medium column page-content">
        <div className="column">
          <ProjectHeaderComponent data={data.strapiProject} />
          <ProjectLinksComponent data={data.strapiProject} />
        </div>
        {!!data.strapiProject.articles.length &&
          <NewsList isLarge={true} titleClass="is-3" contentClass="is-size-4" title="News" news={news} />}
        {data.strapiProject.medium_handle &&
          <MediumAtricles title="Medium Articles" mediumPage={data.strapiProject.medium_handle} />}
        {data.strapiProject.twitter_link &&
          <TwitterTimeline title="Twitter Timeline" profile={twitterProfileName} />}
      </div>
    </Layout>
  )
}
export default ProjectTemplate
export const query = graphql`  
  query ProjectTemplate($id: String) {
    strapiProject(id: {eq: $id}) {
      name
      long_description
      grant
      github_link
      website_link
      telegram_link
      twitter_link
      medium_link
      medium_handle
      reddit_link
      youtube_link
      discord_link
      riot_link
      subsocial_link
      whitepaper_link
      articles{
        project
      }
      email
      logo {
        childImageSharp {
          fixed(width: 90, height: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }      
    }
    allStrapiArticle {
      edges {
        node {
          id
          content
          author
          title
          creationTime
          project{
            id
          }
          image {
            childImageSharp {
              fluid(quality:100) {
                ...GatsbyImageSharpFluid
              }
            }
          }       
        }
      }
    }
  }
`